import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Links from '~/components/elements/Links'
import type { Page, Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerWhitepaper({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<{ page: Page }>()

  return (
    <section
      data-component="BannerWhitepaper"
      className={clsx(page?.databaseId !== 4750 && 'header--margin--top--negative', 'bg-site-base ')}
    >
      <div className={clsx(page?.databaseId === 4750 && 'pb-[100px]', 'container pt-[100px] relative')}>
        <h2
          className={clsx(
            page?.databaseId === 4750 ? 'sm:bottom-32' : 'sm:-bottom-3',
            'animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-100 absolute max-sm:top-[100px] title--semi-unit border-text--white text-transparent'
          )}
        >
          {fields?.subtitle}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 relative sm:pb-20">
          <div className="lg:pl-[100px] max-sm:flex max-sm:justify-center">
            <div className="relative">
              <LossePlaatjie
                maxwidth={600}
                className="animate-fade-up animate-ease-in-out animate-duration-500 w-[250px] lg:w-[300px]"
                src={fields?.image}
              />
              <div className="sm:hidden block bg-gradient-to-t from-site-base to-transparent absolute h-full w-full left-0 bottom-0" />
            </div>
          </div>
          <div className="flex max-sm:pb-6 animate-fade-up animate-ease-in-out animate-duration-500 animate-delay-200 flex-col justify-center max-sm:-mt-10 relative z-20">
            <h1 className="text-white title--medium pb-4 sm:pb-6">{fields?.title}</h1>
            <Content className="lg:pr-[120px]" theme="dark">
              {fields?.description}
            </Content>
            <Links className="pt-4 sm:pt-6" items={fields?.links} />
          </div>
        </div>
      </div>
    </section>
  )
}
